import React from "react";
import SimpleBar from "simplebar-react";
import { detectDeviceType } from "../../../utils/deviceType";
import Header from "./One/Header";
import About from "./One/About";
// import RegisterNow from "./One/RegisterNow";
import FreeRegisterNow from "./One/FreeRegisterNow";
import BuyTickets from "../EventLanding/BuyTickets";
import EventPlaceholder from "../../../assets/images/placeholder/EventPplaceholder.png";
import CustomFooter from "../../../Layout/HorizontalLayout/CustomFooter";

const EventOne = ({ eventDetails }) => {
  const deviceType = detectDeviceType();

  // console.log("eventDetails...", JSON.stringify(eventDetails, null, 2));

  return (
    <React.Fragment>
      <div className="new-event-registration-page">
        <Header eventDetails={eventDetails} deviceType={deviceType} />
        <div className="w-100">
          <SimpleBar style={{ height: "83vh" }}>
            <div className="new-event-registration-page-banner">
              <img
                src={
                  eventDetails?.bannerImage
                    ? eventDetails?.bannerImage
                    : EventPlaceholder
                }
                alt="Event Banner"
              />
            </div>
            <div className="w-100 pt-3 pb-3">
              <About eventDetails={eventDetails} />
            </div>
            {eventDetails?.ticketsSold ? (
              <div className="mt-4">
                <BuyTickets
                  eventID={eventDetails?._id}
                  eventDetails={eventDetails}
                />
              </div>
            ) : null}
            {eventDetails?.ticketsSold === false ? (
              <div className="mt-4">
                <FreeRegisterNow
                  eventID={eventDetails?._id}
                  eventDetails={eventDetails}
                  redirect={eventDetails?.redirectUrl}
                />
              </div>
            ) : null}
          </SimpleBar>
        </div>
      </div>
      <div className="w-100">
        <CustomFooter />
      </div>
    </React.Fragment>
  );
};

export default EventOne;
