import * as XLSX from "xlsx";

export const exportRegisteredAttendee = (data) => {
  if (!data || data.length === 0) return;

  // Extract all unique custom field labels
  const allCustomLabels = [
    ...new Set(data.flatMap((attendee) => attendee.customFields?.map((field) => field.label) || [])),
  ];

  // Map attendees to include custom fields dynamically
  const formattedData = data.map(({ firstName, lastName, email, phoneNumber, customFields }) => {
    const customFieldValues = customFields?.reduce((acc, { label, value }) => {
      acc[label] = value;
      return acc;
    }, {}) || {};

    return {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      PhoneNumber: phoneNumber,
      ...allCustomLabels.reduce((acc, label) => {
        acc[label] = customFieldValues[label] || ""; // Ensure all columns exist
        return acc;
      }, {}),
    };
  });

  // Create workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(formattedData);

  // Append worksheet to workbook and save file
  XLSX.utils.book_append_sheet(workbook, worksheet, "RegisteredAttendees");
  XLSX.writeFile(workbook, "RegisteredAttendees.xlsx");
};

// export const exportRegisteredAttendee = (data) => {
//   const workbook = XLSX.utils.book_new();
//   const worksheet = XLSX.utils.json_to_sheet(
//     data?.map(({ firstName, lastName, email, phoneNumber }) => ({
//       FirstName: firstName,
//       LastName: lastName,
//       Email: email,
//       PhoneNumber: phoneNumber,
//     }))
//   );

//   XLSX.utils.book_append_sheet(workbook, worksheet, "RegisterAttendee");
//   XLSX.writeFile(workbook, "RegisterAttendee.xlsx");
// };
