import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../../../Toast/Toast";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";

const FormWhatsApp = ({
  attendeeID,
  eventID,
  token,
  setIsToggleViewWhatsApp,
}) => {
  const [inputs, setInputs] = useState({});
  const [userRole, setUserRole] = useState("all");
  const [btnLoad, setBtnLoad] = useState(false);
  const queryClient = useQueryClient();
  const key = ["allWhatsApp", eventID];
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSend = (e) => {
    e.preventDefault();
    setBtnLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const raw = JSON.stringify({
      body: inputs?.message,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API}communication/whatsapp?aid=${attendeeID}&eid=${eventID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setIsToggleViewWhatsApp(false);
        setBtnLoad(false);
        if (res?.status === "success") {
          queryClient.invalidateQueries(key);
          successMessage("WhatsApp sucessfully sent");
        } else {
          warningMessage("No phone number found");
        }
      })
      .catch((error) => {
        setIsToggleViewWhatsApp(false);
        setBtnLoad(false);
        dangerMessage("No phone number found");
        console.error(error);
      });
  };

  return (
    <div>
      <form onSubmit={handleSend}>
        <label className="event__input__form__Creation">
          Message<span className="text-danger">*</span>
          <textarea
            name="message"
            value={inputs.message}
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className=" mt-1 form-control fromInput"
            rows={5}
            required
          ></textarea>
        </label>

        <div className="w-100 mt-2 d-flex justify-content-center">
          <ButtonSubmit
            Title="Send WhatsApp"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={handleSend}
            pending={btnLoad}
          />
        </div>
      </form>
    </div>
  );
};

export default FormWhatsApp;
